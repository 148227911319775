/**
 * App
 */

import 'Utils/closest.polyfill.js';
import 'Utils/matches.polyfill.js';
import 'Utils/slick-lightbox.min.js';

import navbar from 'Components/navbar';

document.addEventListener('DOMContentLoaded', function() {
  navbar();
});

$('#gallery').slickLightbox();
$('#gallery').slick({
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  centerMode: true,
  variableWidth: true
});

$(document).ready(function() {
  setTimeout(function(){ $("#preloader").fadeOut( "slow" ); }, 1000);
});